import React, { Component } from "react";
import Fade from "react-reveal";

let id = 0;
class Projects extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      
      return (
        <div key={id++} className="portfolio-item">
          <div>
            <h3 style={{ textAlign: "center" }}>{projects.title}</h3>
            <div style={{ textAlign: "left", clear: "both" }}>{projects.subtitle}</div>
            <div style={{ textAlign: "left" }}>{projects.details}</div>
          </div>
        </div>
      );
    });

    return (
      <section id="projects">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Projects</h1>

              <div
                id="projects-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Projects;
